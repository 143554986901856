<template>
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <div class="list-operation">
        <el-button type="primary" size="small" @click="handleAdd">
          新增
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import { selectGuarGreditFundList, guarGreditFundUpdateState } from '@/api/tradeFinancing.js'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      total: 0,
      loading: false,
      listData: [],
      formInline: {
        fundCmpName: '', // 授信资金方
        guarCmpName: '', // 担保方
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '授信资金方', value: 'fundCmpName' },
        { type: 'input', label: '担保方', value: 'guarCmpName' }
      ],
      itemData: [
        { label: '授信资金方', prop: 'fundCmpName', width: 220 },
        { label: '担保方', prop: 'guarCmpName', width: 120 },
        { label: '授信额度', prop: 'creditAmt', width: 280 },
        { label: '到期日', prop: 'endDate', width: 180 },
        { label: '融资利率(%年化)', prop: 'execRate', width: 180 },
        { label: '融资手续费率(%年化)', prop: 'execFee', width: 180 },
        { label: '状态', prop: 'creditState', child: [{ dictName: '有效', dictId: 0 }, { dictName: '失效', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'text', label: '详情', handleEvent: this.viewDetails },
        { bType: 'text', label: '编辑', handleEvent: this.editCreditOrganization, child: [{ val: 'editOrStartButton' }] },
        { bType: 'text', label: '启用', handleEvent: this.handleEnable, child: [{ val: 'editOrStartButton' }] },
        { bType: 'text', label: '停用', handleEvent: this.handleEnable, child: [{ val: 'isStopButton' }] }
      ]
    }
  },
  methods: {
    handleAdd() {
      this.$router.push('/productManage/addCreditOrganization')
    },
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      selectGuarGreditFundList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list.map(item => {
          // 控制编辑按钮
          if (item.creditState === '02') {
            item.editOrStartButton = true
            item.isStopButton = false
          } else {
            item.editOrStartButton = false
            item.isStopButton = true
          }
          return item
        })
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    viewDetails({ id }) {
      this.$router.push({
        path: '/productManage/creditOrganizationDetail',
        query: {
          id
        }
      })
    },
    editCreditOrganization({ id }) {
      this.$router.push({
        path: '/productManage/editCreditOrganization',
        query: {
          id
        }
      })
    },
    // 启用-停用
    handleEnable(row) {
      const stat = row.creditState === '01' ? '停用' : '启用'
      this.$confirm(`您确定要${stat}当前授信机构吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id,
          creditState: '01' // 状态 01有效，02无效
        }
        if (row.creditState === '01') {
          // 已启用状态 去停用
          params.creditState = '02'
          this.handleEnableAjax(params, '停用成功')
        } else {
          params.creditState = '01'
          this.handleEnableAjax(params, '启用成功')
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleEnableAjax(params, msg) {
      guarGreditFundUpdateState(params, res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.getDate()
        }
      })
    }
  }
}
</script>
